import {HostListener, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  screenHeight: number;
  screenWidth: number;
  username: string;
  time_1: string;
  time_2: string;
  assetsUrl = "assets/animation/"
  harryIntro = "intro_1.gif"
  harryLookToUser = "look_to_user_1.gif"
  harryPointToUser = "point_to_dialog.gif"
  harryPointToDialog = "point_to_dialog.gif"
  harrySad = "sad.gif"
  harryVictory = "victory.gif"
  gameLevel;

  itemsLevelOne =
    [
      {
        "id": "si-cl-1",
        "x": 412,
        "y" : 478,
        "height": 49,
        "width": 139,
        "zIndex": 101,
        "image": "assets/quest_items/one/items/box4.png",
        "collected": false,
        "toolbar" : {
          "id": "si-1",
          "image": "assets/quest_items/one/icons/unchecked_nut.png",
          "imageTag" : "nut",
          "imageExtension": ".png",
          "name": "M12",
        }
      },
      {
        "id": "si-cl-2",
        "x": 36,
        "y" : 821,
        "height": 61,
        "width": 119,
        "zIndex": 100,
        "image": "assets/quest_items/one/items/hammer.png",
        "collected": false,
        "toolbar" : {
          "id": "si-2",
          "image": "assets/quest_items/one/icons/unchecked_hammer.png",
          "imageTag" : "hammer",
          "imageExtension": ".png",
          "name": "Hammer",
        }
      },
      {
        "id": "si-cl-3",
        "x": 823,
        "y" : 810,
        "height": 49,
        "width": 139,
        "zIndex": 100,
        "image": "assets/quest_items/one/items/box1.png",
        "collected": false,
        "toolbar" : {
          "id": "si-3",
          "image": "assets/quest_items/one/icons/unchecked_ring.png",
          "imageTag" : "ring",
          "imageExtension": ".png",
          "name": "M6",
        }
      },
      {
        "id": "si-cl-4",
        "x": 214,
        "y" : 478,
        "height": 49,
        "width": 139,
        "zIndex": 99,
        "image": "assets/quest_items/one/items/box7.png",
        "collected": false,
        "toolbar" : {
          "id": "si-4",
          "image": "assets/quest_items/one/icons/unchecked_bolt.png",
          "imageTag" : "bolt",
          "imageExtension": ".png",
          "name": "M16",
        }
      },
      {
        "id": "si-cl-5",
        "x": 330,
        "y" : 433,
        "height": 100,
        "width": 136,
        "zIndex": 102,
        "image": "assets/quest_items/one/items/ty-wrap.png",
        "collected": false,
        "toolbar" : {
          "id": "si-5",
          "image": "assets/quest_items/one/icons/unchecked_ty_wrap.png",
          "imageTag" : "ty_wrap",
          "imageExtension": ".png",
          "name": "Ty Wraps",
        }
      },
      {
        "id": "si-cl-6",
        "x": 1137,
        "y" : 832,
        "height": 58,
        "width": 101,
        "zIndex": 99,
        "image": "assets/quest_items/one/items/pincer.png",
        "collected": false,
        "toolbar" : {
          "id": "si-6",
          "image": "assets/quest_items/one/icons/unchecked_pincer.png",
          "imageTag" : "pincer",
          "imageExtension": ".png",
          "name": "Vice Grip",
        }
      }
    ];
  itemsLevelTwo =
    [
      {
        "id": "si-drag-drop-item-1",
        "x": 412,
        "y" : 478,
        "height": 49,
        "width": 139,
        "zIndex": 101,
        "image": "assets/quest_items/two/items/box4.png",
        "collected": false,
        "area":{
          "id": "si-drag-drop-item-1-placed-area",
          "width": 231,
          "height": 324,
          "y": 132,
          "x": 871,
          "image": "assets/quest_items/two/items/box4.png",
        },
        "toolbar" : {
          "id": "si-1",
          "image": "assets/quest_items/two/icons/unchecked_nut.png",
          "imageTag" : "nut",
          "imageExtension": ".png",
          "name": "M12",
        }
      },
      {
        "id": "si-drag-drop-item-2",
        "x": 36,
        "y" : 821,
        "height": 61,
        "width": 119,
        "zIndex": 100,
        "image": "assets/quest_items/two/items/hammer.png",
        "collected": false,
        "area":{
          "id": "si-drag-drop-item-2-placed-area",
          "x": 373,
          "y" : 193,
          "height": 146,
          "width": 53,
          "image": "assets/quest_items/two/items/hammer-placed.png",
        },
        "toolbar" : {
          "id": "si-2",
          "image": "assets/quest_items/two/icons/unchecked_hammer.png",
          "imageTag" : "hammer",
          "imageExtension": ".png",
          "name": "Hammer",
        }
      },
      {
        "id": "si-drag-drop-item-3",
        "x": 823,
        "y" : 810,
        "height": 49,
        "width": 139,
        "zIndex": 100,
        "image": "assets/quest_items/two/items/box1.png",
        "collected": false,
        "area":{
          "id": "si-drag-drop-item-3-placed-area",
          "width": 231,
          "height": 324,
          "y": 132,
          "x": 871,
          "image": "assets/quest_items/two/items/box1.png",
        },
        "toolbar" : {
          "id": "si-3",
          "image": "assets/quest_items/two/icons/unchecked_ring.png",
          "imageTag" : "ring",
          "imageExtension": ".png",
          "name": "M6",
        }
      },
      {
        "id": "si-drag-drop-item-4",
        "x": 214,
        "y" : 478,
        "height": 49,
        "width": 139,
        "zIndex": 99,
        "image": "assets/quest_items/two/items/box7.png",
        "collected": false,
        "area":{
          "id": "si-drag-drop-item-4-placed-area",
          "width": 231,
          "height": 324,
          "y": 132,
          "x": 871,
          "image": "",
        },
        "toolbar" : {
          "id": "si-4",
          "image": "assets/quest_items/two/icons/unchecked_bolt.png",
          "imageTag" : "bolt",
          "imageExtension": ".png",
          "name": "M16",
        }
      },
      {
        "id": "si-drag-drop-item-5",
        "x": 330,
        "y" : 433,
        "height": 100,
        "width": 136,
        "zIndex": 102,
        "image": "assets/quest_items/two/items/ty-wrap.png",
        "collected": false,
        "area":{
          "id": "si-drag-drop-item-5-placed-area",
          "x": 1031,
          "y" : 498,
          "height": 53,
          "width": 67,
          "image": "assets/quest_items/two/items/tie-wrap-placed.png",
        },
        "toolbar" : {
          "id": "si-5",
          "image": "assets/quest_items/two/icons/unchecked_ty_wrap.png",
          "imageTag" : "ty_wrap",
          "imageExtension": ".png",
          "name": "Tie Wraps",
        }
      },
      {
        "id": "si-drag-drop-item-6",
        "x": 1137,
        "y" : 832,
        "height": 58,
        "width": 101,
        "zIndex": 99,
        "image": "assets/quest_items/two/items/pincer.png",
        "collected": false,
        "area":{
          "id": "si-drag-drop-item-6-placed-area",
          "x": 417,
          "y" : 374,
          "height": 122,
          "width": 38,
          "image": "assets/quest_items/two/items/pincer-placed.png",
        },
        "toolbar" : {
          "id": "si-6",
          "image": "assets/quest_items/two/icons/unchecked_pincer.png",
          "imageTag" : "pincer",
          "imageExtension": ".png",
          "name": "Vice Grip",
        }
      }
    ];
  itemsLevelThree =
    [
    {
      "id": "si-drag-drop-row-one",
      "items": ['assets/box1.png', 'assets/box2.png', 'assets/box3.png'],
      "result" : ['assets/box8.png', 'assets/box4.png', 'assets/box6.png'],
      "collected": false,
      "toolbar" : {
        "id": "si-row-1",
        "image": "assets/quest_items/three/icons/unchecked_nut.png",
        "imageTag" : "nut",
        "imageExtension": ".png",
        "name": "Nuts",
      }
    },
    {
      "id": "si-drag-drop-row-two",
      "items": ['assets/box4.png', 'assets/box5.png', 'assets/box6.png'],
      "result" : ['assets/box9.png', 'assets/box5.png', 'assets/box7.png'],
      "collected": false,
      "toolbar" : {
        "id": "si-row-2",
        "image": "assets/quest_items/three/icons/unchecked_bolt.png",
        "imageTag" : "bolt",
        "imageExtension": ".png",
        "name": "Bolts",
      }
    },
    {
      "id": "si-drag-drop-row-three",
      "items": ['assets/box7.png', 'assets/box8.png', 'assets/box9.png'],
      "result" : ['assets/box1.png', 'assets/box2.png', 'assets/box3.png'],
      "collected": false,
      "toolbar" : {
        "id": "si-row-3",
        "image": "assets/quest_items/three/icons/unchecked_ring.png",
        "imageTag" : "ring",
        "imageExtension": ".png",
        "name": "Rings",
      }
    }
  ]
  itemsLevelFour =
    [{
    'labels': [
      {
        "id": "si-red-label-1-signed",
        "image": "assets/quest_items/four/items/red_label_template.png",
        "imageTag" : "red-label",
        "imageExtension": ".png",
        "x": 878,
        "y" : 474,
        "height": 102,
        "width": 65,
      },
      {
        "id": "si-red-label-2-signed",
        "image": "assets/quest_items/four/items/red_label_template.png",
        "imageTag" : "red-label",
        "imageExtension": ".png",
        "x": 878,
        "y" : 474,
        "height": 102,
        "width": 65,
      },
      {
        "id": "si-red-label-3-signed",
        "image": "assets/quest_items/four/items/red_label_template.png",
        "imageTag" : "red-label",
        "imageExtension": ".png",
        "x": 878,
        "y" : 474,
        "height": 102,
        "width": 65,
      }],
    'items':
      [
      {
        "id": "si-item1-placed-area",
        "x": 742,
        "y" : 726,
        "height": 166,
        "width": 163,
        "image": "assets/quest_items/four/items/pump-1.png",
        "imageTag" : "pump-1",
        "imageExtension": ".png",
        "collected": false,
        "toolbar" : {
          "id": "si-red-label-1",
          "image": "assets/quest_items/four/icons/unchecked_value_1.png",
          "imageTag" : "value_1",
          "imageExtension": ".png",
          "name": "Pump",
          "x": 70,
          "y" : 10,
          "height": 140,
          "width": 35,
        }
      },
      {
        "id": "si-item2-placed-area",
        "x": 132,
        "y" : 448,
        "height": 86,
        "width": 87,
        "image": "assets/quest_items/four/items/value-2.png",
        "imageTag" : "value-2",
        "imageExtension": ".png",
        "collected": false,
        "toolbar" :
          {
            "id": "si-red-label-2",
            "image": "assets/quest_items/four/icons/unchecked_value_2.png",
            "imageTag" : "value_2",
            "imageExtension": ".png",
            "name": "Small valve",
            "x": 140,
            "y" : 10,
            "height": 140,
            "width": 240,
          }
      },
      {
        "id": "si-item3-placed-area",
        "x": 938,
        "y" : 764,
        "height": 127,
        "width": 236,
        "image": "assets/quest_items/four/items/value-1.png",
        "imageTag" : "value-1",
        "imageExtension": ".png",
        "collected": false,
        "toolbar" : {
          "id": "si-red-label-3",
          "image": "assets/quest_items/four/icons/unchecked_value_3.png",
          "imageTag" : "value_3",
          "imageExtension": ".png",
          "name": "Big valve",
          "x": 210,
          "y" : 10,
          "height": 140,
          "width": 35,
        }
      }
    ]
  }];
  /*harrySad = "intro_1.gif"
  harryVictory = "intro_1.gif"*/

  itemsLevelFive=
    [
      {
        "id": "si-move-item-1",
        "x": 742,
        "y" : 726,
        "height": 166,
        "width": 163,
        "image": "assets/quest_items/four/items/pump-1-labeled.png",
        "imageTag" : "pump-1",
        "imageExtension": ".png",
        "collected": false,
        "toolbar" : {
          "id": "si-red-label-1",
          "image": "assets/quest_items/four/icons/unchecked_value_1.png",
          "imageTag" : "value_1",
          "imageExtension": ".png",
          "name": "Pump",
          "x": 70,
          "y" : 10,
          "height": 140,
          "width": 35,
        }
      },
      {
        "id": "si-move-item-2",
        "x": 132,
        "y" : 448,
        "height": 86,
        "width": 87,
        "image": "assets/quest_items/four/items/value-2-labeled.png",
        "imageTag" : "value-2",
        "imageExtension": ".png",
        "collected": false,
        "toolbar" :
          {
            "id": "si-red-label-2",
            "image": "assets/quest_items/four/icons/unchecked_value_2.png",
            "imageTag" : "value_2",
            "imageExtension": ".png",
            "name": "Small valve",
            "x": 140,
            "y" : 10,
            "height": 140,
            "width": 240,
          }
      },
      {
        "id": "si-move-item-3",
        "x": 938,
        "y" : 764,
        "height": 127,
        "width": 236,
        "image": "assets/quest_items/four/items/value-1-labeled.png",
        "imageTag" : "value-1",
        "imageExtension": ".png",
        "collected": false,
        "toolbar" : {
          "id": "si-red-label-3",
          "image": "assets/quest_items/four/icons/unchecked_value_3.png",
          "imageTag" : "value_3",
          "imageExtension": ".png",
          "name": "Big valve",
          "x": 210,
          "y" : 10,
          "height": 140,
          "width": 35,
        }
      }
    ]
  itemsLevelSix =
    [
      {
        "id": "si-cl-1",
        "width": 231,
        "height": 324,
        "y": 190,
        "x": 897,
        "zIndex": 4,
        "image": "assets/quest_items/six/items/box4.png",
        "collected": false,
        "toolbar" : {
          "id": "si-1",
          "image": "assets/quest_items/six/icons/unchecked_nut.png",
          "imageTag" : "nut",
          "imageExtension": ".png",
          "name": "M12",
        }
      },
      {
        "id": "si-cl-2",
        "x": 373,
        "y" : 193,
        "height": 146,
        "width": 53,
        "zIndex": 100,
        "image": "assets/quest_items/six/items/hammer.png",
        "collected": false,
        "toolbar" : {
          "id": "si-2",
          "image": "assets/quest_items/six/icons/unchecked_hammer.png",
          "imageTag" : "hammer",
          "imageExtension": ".png",
          "name": "Hammer",
        }
      },
      {
        "id": "si-cl-3",
        "y": 411,
        "x": 871,
        "height": 49,
        "width": 139,
        "zIndex": 6,
        "image": "assets/quest_items/six/items/box1.png",
        "collected": false,
        "toolbar" : {
          "id": "si-3",
          "image": "assets/quest_items/six/icons/unchecked_ring.png",
          "imageTag" : "ring",
          "imageExtension": ".png",
          "name": "M6",
        }
      },
      {
        "id": "si-cl-4",
        "y": 301,
        "x": 967,
        "height": 49,
        "width": 139,
        "zIndex": 2,
        "image": "assets/quest_items/six/items/box7.png",
        "collected": false,
        "toolbar" : {
          "id": "si-4",
          "image": "assets/quest_items/six/icons/unchecked_bolt.png",
          "imageTag" : "bolt",
          "imageExtension": ".png",
          "name": "M16",
        }
      },
      {
        "id": "si-cl-5",
        "x": 1031,
        "y" : 498,
        "height": 53,
        "width": 67,
        "zIndex": 99,
        "image": "assets/quest_items/six/items/ty-wrap.png",
        "collected": false,
        "toolbar" : {
          "id": "si-5",
          "image": "assets/quest_items/six/icons/unchecked_ty_wrap.png",
          "imageTag" : "ty_wrap",
          "imageExtension": ".png",
          "name": "Ty Wraps",
        }
      },
      {
        "id": "si-cl-6",
        "x": 417,
        "y" : 374,
        "height": 122,
        "width": 38,
        "zIndex": 99,
        "image": "assets/quest_items/six/items/pincer.png",
        "collected": false,
        "toolbar" : {
          "id": "si-6",
          "image": "assets/quest_items/six/icons/unchecked_pincer.png",
          "imageTag" : "pincer",
          "imageExtension": ".png",
          "name": "Vice Grip",
        }
      }
    ];

  constructor() { }

  getDialogTexts(): any {
    return [
      {
        "game_state": "dialog_1",
        "title": "Welkom " + this.username + "!",
        "text": "Met deze mini- game volgens het 5S - principe scoor je bonuspunten voor je team.Het duurt " +
          "gemiddeld 10 minuten en je hebt 1 poging. <br> Weet je zeker dat je NU wilt starten ?",
        "button_left": {
          "visable": true,
          "html": "<span id=\"button-left\"> " +
            "<ion-icon name='chevron-back-outline' class='color-white vertical-align-bottom'></ion-icon>" +
            "<span class= \"vertical-align-middle color-white d-inline-block ms-1\">Later spelen</span>" +
            "</span>"
        },
        "button_right": {
          "visable": true,
          "html": "<span id=\"button-right\" > " +
            "<span class= \"vertical-align-middle color-white d-inline-block me-1\">Ja doorgaan</span>" +
            "</span>" +
            "<ion-icon name='chevron-forward-outline' class='color-white vertical-align-bottom'></ion-icon>"
        }
      },
      {
        "game_state": "dialog_2",
        "title": "Level 1",
        "text": "Er is een spoedklus, hiervoor moeten er 6 voorwerpen worden gezocht in het magazijn. Probeer deze te vinden in zo’n kort mogelijke tijd.Het magazijn is nu nog een rommel, dit gaan we later opruimen.",
        "button_left": {
          "visable": false,
          "html": ""
        },
        "button_right": {
          "visable": true,
          "html": "<span id=\"button-right\" > " +
            "<span class= \"vertical-align-middle color-white d-inline-block me-1\">Verder</span>" +
            "</span>" +
            "<ion-icon name='chevron-forward-outline' class='color-white vertical-align-bottom'></ion-icon>"
        }
      },
      {
        "game_state": "dialog_3",
        "title": "Level 2",
        "text": "Oh nee?! " + this.time_1 + " dat moet sneller kunnen...We gaan nu het magazijn ordenen, sleep de items van de werkbank naar hun plaats. En orden het schap naar soort en zet items op volgorde van grote.",
        "button_left": {
          "visable": false,
          "html": ""
        },
        "button_right": {
          "visable": true,
          "html": "<span id=\"button-right\" > " +
            "<span class= \"vertical-align-middle color-white d-inline-block me-1\">Verder</span>" +
            "</span>" +
            "<ion-icon name='chevron-forward-outline' class='color-white vertical-align-bottom'></ion-icon>"
        }
      },
      {
        "game_state": "dialog_4",
        "title": "Level 3",
        "text": "Probeer nu opnieuw je tijd van " + this.time_1 + " te verbeteren in het opgeruimde magazijn. Zoek weer 6 items bij elkaar.",
        "button_left": {
          "visable": false,
          "html": ""
        },
        "button_right": {
          "visable": true,
          "html": "<span id=\"button-right\" > " +
            "<span class= \"vertical-align-middle color-white d-inline-block me-1\">Verder</span>" +
            "</span>" +
            "<ion-icon name='chevron-forward-outline' class='color-white vertical-align-bottom'></ion-icon>"
        }
      },
      {
        "game_state": "dialog_5",
        "title": "Uitslag",
        "text": "Tijd om wat spullen te labelen. Label 3 spullen op de werkbank. Schrijf eerst je naam en de datum vandaag, daarna label je de spullen.",
        "button_left": {
          "visable": false,
          "html": ""
        },
        "button_right": {
          "visable": true,
          "html": "<span id=\"button-right\" > " +
            "<span class= \"vertical-align-middle color-white d-inline-block me-1\">Verder</span>" +
            "</span>" +
            "<ion-icon name='chevron-forward-outline' class='color-white vertical-align-bottom'></ion-icon>"
        }
      },
      {
        "game_state": "dialog_6",
        "title": "Dialog 6",
        "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sagittis dui a porttitor tristique. Donec euismod tempor ultrices. Sed mollis diam libero. In semper vulputate sagittis. In aliquet vitae ex blandit congue. Integer volutpat interdum enim, nec mollis leo venenatis vitae. In bibendum lobortis nulla et sollicitudin. Morbi vitae ultricies risus. Proin sagittis, dui quis bibendum vestibulum, sem nisl sagittis ligula, id ullamcorper risus purus aliquam lorem. Cras et egestas ante. Fusce non luctus massa. Phasellus vel purus lectus. In hac habitasse platea dictumst.",
        "button_left": {
          "visable": false,
          "html": ""
        },
        "button_right": {
          "visable": true,
          "html": "<span id=\"button-right\" > " +
            "<span class= \"vertical-align-middle color-white d-inline-block me-1\">Verder</span>" +
            "</span>" +
            "<ion-icon name='chevron-forward-outline' class='color-white vertical-align-bottom'></ion-icon>"
        }
      },
      {
        "game_state": "dialog_7",
        "title": "Dialog 7",
        "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sagittis dui a porttitor tristique. Donec euismod tempor ultrices. Sed mollis diam libero. In semper vulputate sagittis. In aliquet vitae ex blandit congue. Integer volutpat interdum enim, nec mollis leo venenatis vitae. In bibendum lobortis nulla et sollicitudin. Morbi vitae ultricies risus. Proin sagittis, dui quis bibendum vestibulum, sem nisl sagittis ligula, id ullamcorper risus purus aliquam lorem. Cras et egestas ante. Fusce non luctus massa. Phasellus vel purus lectus. In hac habitasse platea dictumst.",
        "button_left": {
          "visable": false,
          "html": ""
        },
        "button_right": {
          "visable": true,
          "html": "<span id=\"button-right\" > " +
            "<span class= \"vertical-align-middle color-white d-inline-block me-1\">Verder</span>" +
            "</span>" +
            "<ion-icon name='chevron-forward-outline' class='color-white vertical-align-bottom'></ion-icon>"
        }
      },
      {
        "game_state": "dialog_8",
        "title": "Dialog 8",
        "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sagittis dui a porttitor tristique. Donec euismod tempor ultrices. Sed mollis diam libero. In semper vulputate sagittis. In aliquet vitae ex blandit congue. Integer volutpat interdum enim, nec mollis leo venenatis vitae. In bibendum lobortis nulla et sollicitudin. Morbi vitae ultricies risus. Proin sagittis, dui quis bibendum vestibulum, sem nisl sagittis ligula, id ullamcorper risus purus aliquam lorem. Cras et egestas ante. Fusce non luctus massa. Phasellus vel purus lectus. In hac habitasse platea dictumst.",
        "button_left": {
          "visable": false,
          "html": ""
        },
        "button_right": {
          "visable": true,
          "html": "<span id=\"button-right\" > " +
            "<span class= \"vertical-align-middle color-white d-inline-block me-1\">Afsluiten</span>" +
            "</span>" +
            "<ion-icon name='chevron-forward-outline' class='color-white vertical-align-bottom'></ion-icon>"
        }
      },
    ]
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    let body = document.body,
      html = document.documentElement;

    let height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    let width = Math.max(
      body.scrollWidth,
      body.offsetWidth,
      html.clientWidth,
      html.scrollWidth,
      html.offsetWidth
    );

    return {
      "width": width,
      "height": height
    }

  }

  placedItemCollision(droppedItem: any, placeArea: any): boolean {
    // console.log(droppedItem.left, droppedItem.top, droppedItem.height, droppedItem.width)
    // console.log(placeArea.left, placeArea.top, placeArea.height, placeArea.width)
    return !(
      (placeArea.left > droppedItem.left + droppedItem.width) ||
      (placeArea.left + placeArea.width < droppedItem.left) ||
      (placeArea.top > droppedItem.top + droppedItem.height) ||
      (placeArea.top + placeArea.height < droppedItem.top)
    );
  }
}
