import {Component, DoCheck, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { UtilsService } from './services/utils.service';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{
  public screenMode: string;
  constructor(private utilsService: UtilsService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(){
  }
}
